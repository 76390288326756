
$(document).on('turbolinks:load', function () {

  $('.select2').select2({
    language: "pt-BR",
    // Opções adicionais conforme necessário
  });

  
  $('[data-toggle="offcanvas"]').on('click', function () {
    $('.offcanvas-collapse').removeClass('open')
})  
  

  $(document).keydown(function(e) {
    var code = e.keyCode || e.which;
   
    if (code == 27) {
      $('.offcanvas-collapse').removeClass('open')
    }
  });

  $('#inventory_user_ids').multiSelect({
    selectableHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectableFooter: "<div class='custom-footer-contigencies'>Todos os usuários</div>",
    selectionHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectionFooter: "<div class='custom-footer-contigencies'>Usuários selecionados</div>",  
  });   
  
   $('#farm_user_ids').multiSelect({
    selectableHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectableFooter: "<div class='custom-footer-contigencies'>Todos os proprietários</div>",
    selectionHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectionFooter: "<div class='custom-footer-contigencies'>Proprietários selecionados</div>",  
  });   
  
  $('#user_farm_ids').multiSelect({
    selectableHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectableFooter: "<div class='custom-footer-contigencies'>Todas as fazendas</div>",
    selectionHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectionFooter: "<div class='custom-footer-contigencies'>Fazendas selecionadas</div>",  
  });

  $('#merchandise_service_supplier_ids').multiSelect({
    selectableHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectableFooter: "<div class='custom-footer-contigencies'>Todos os Fornecedores</div>",
    selectionHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectionFooter: "<div class='custom-footer-contigencies'>Fornecedores selecionados</div>",  
  });

  $('#supplier_merchandise_service_ids').multiSelect({
    selectableHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectableFooter: "<div class='custom-footer-contigencies'>Todas as Merc./Ser.</div>",
    selectionHeader: "<input type='text' class='search-input-contigencies' autocomplete='off' placeholder='Localizar'>",
    selectionFooter: "<div class='custom-footer-contigencies'>Merc./Ser. selecionadas</div>",  
  });

  $("#users-carousel").owlCarousel({
    items: 4,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 5000,
    loop: false,
    responsive: {
      0: {
        items: 2
      },
      578: {
        items: 4
      },
      768: {
        items: 4
      }
    }
  });
  

    const links = document.querySelectorAll("a[data-remote]");
  
    links.forEach((element) => {
      element.addEventListener("ajax:beforeSend", () => {
        element.classList.add("btn-progress");
      });
      element.addEventListener("ajax:complete", () => {
        element.classList.remove("btn-progress");
      });
    });
  
    $('.panel-heading .clickable').on("click", function (e) {
        if ($(this).hasClass('panel-collapsed')) {
          // expand the panel
          $(this).parents('.panel').find('.panel-body').slideDown();
          $(this).removeClass('panel-collapsed');
          $(this).find('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        }
        else {
          // collapse the panel
          $(this).parents('.panel').find('.panel-body').slideUp();
          $(this).addClass('panel-collapsed');
          $(this).find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        }
    });

    $('#messages .group-rom .odd span.' + $("#data_user_token").data('source')).addClass('text-success');

  checkFormChat();

});

window.showPreLoader = function(target) {
  $(".preloader").fadeIn();
  $(".content-preloader").hide();
}

window.hiddenPreLoader = function(target) {
  $(".preloader").fadeOut();
  $(".content-preloader").fadeIn();
}


window.showFullLoader = function(target) {
  $(".page-loader").fadeIn();
}

window.hiddenFullLoader = function(target) {
  $(".page-loader").fadeOut();  
}

window.listeningStateChange = function(object) {
  $("#" +object+"_state_id").on('change', function () {
      $("#"+object+"_city_id").find("option:gt(0)").remove();
      $("#"+object+"_city_id").find("option:first").text("Aguarde...");

      $.getJSON("/cidades/por-estado", {
          state_id: $(this).val()
      }, function (json) {
          $("#"+object+"_city_id").find("option:first").text("");
          for (var i = 0; i < json.length; i++) {
              $("<option/>").attr("value", json[i].id).text(json[i].name).appendTo($("#"+object+"_city_id"));
          }

          $("#"+object+"_city_id").removeAttr("disabled");
      });
  });
}

window.listeningStateChangeCocoon = function(stateID, cityID) {
  $("#" + stateID).on('change', function () {
      $("#" + cityID).find("option:gt(0)").remove();
      $("#" + cityID).find("option:first").text("Aguarde...");

      $.getJSON("/cidades/por-estado", {
          state_id: $(this).val()
      }, function (json) {
          $("#" + cityID).find("option:first").text("");
          for (var i = 0; i < json.length; i++) {
              $("<option/>").attr("value", json[i].id).text(json[i].name).appendTo($("#" + cityID));
          }

          $("#" + cityID).removeAttr("disabled");
      });
  });
}


window.checkFormChat = function () {
  $('#chat_message_message').on('keyup', function () {
    let empty = false;

    $('#chat_message_message').each(function () {
      empty = $(this).val().length == 0;
    });

    if (empty)
      $('.chat-form button').prop('disabled', true);
    else
      $('.chat-form button').prop('disabled', false);
  });
}


window.copyToClipboard = function (element) {
  var $temp = $("<input>");
  $("body").append($temp);
  $temp.val($(element).val()).select();
  document.execCommand("copy");

  $(".input-group-text").attr('data-original-title', 'Copiado!').tooltip('show');
  $('.input-group-text').on('hidden.bs.tooltip', function () {
    $(".input-group-text").attr('data-original-title', 'Copiar link do evento');
  })

  $temp.remove();
}


window.prepareEvent = function (data) {

  console.log(data['title']);
  var tData = "";
  tData += "BEGIN:VCALANDER\r\n";
  tData += "VERSION:2.0\r\n";
  tData += "METHOD:PUBLISH\r\n";
  tData += "BEGIN:VEVENT\r\n";
  tData += "SUMMARY:" + data.title + "\r\n";
  tData += "DESCRIPTION:" + data.desc + "\r\n";
  tData += "LOCATION:" + data.location + "\r\n";
  tData += "URL:" + data.url + "\r\n";
  tData += "UID:00" + Math.floor(Math.random() * 10000000) + "-Custom@test\r\n";
  tData += "SEQUENCE:0\r\n";
  tData += "DTSTAMP:" + getUTCTime(data.time.start) + "\r\n";
  tData += "DTSTART:" + getUTCTime(data.time.start) + "\r\n";
  tData += "DTEND:" + getUTCTime(data.time.end) + "\r\n";
  tData += "END:VEVENT\r\n";
  tData += "END:VCALENDAR\r\n";
  return tData;
}



window.adjustToUTC = function (dateObj, zone) {
  var dateOut = new Date(dateObj),
    hours, mins;

  if (isNaN(dateOut.getTime())) {
    return new Date();
  }

  // adjust to UTC
  hours = zone.substring(1, 3);
  mins = zone.substring(4, 6);
  if (zone.substring(0, 1) === '-') {
    dateOut.setHours(dateOut.getHours() + (hours - 0));
    dateOut.setMinutes(dateOut.getMinutes() + (mins - 0));
  } else {
    dateOut.setHours(dateOut.getHours() - hours);
    dateOut.setMinutes(dateOut.getMinutes() - mins);
  }
  return dateOut;
}

window.getDatePart = function (part, digits) {
  part = part.toString();
  while (part.length < digits) {
    part = '0' + part;
  }
  return part;
}

window.getUTCTime = function (dateObj) {
  var newDateObj = adjustToUTC(dateObj, eventData.time.zone);
  return getDatePart(newDateObj.getFullYear(), 4) + getDatePart(newDateObj.getMonth() + 1, 2) + getDatePart(newDateObj.getDate(), 2) + 'T' + getDatePart(newDateObj.getHours(), 2) + getDatePart(newDateObj.getMinutes(), 2) + getDatePart(newDateObj.getSeconds(), 2) + 'Z';
}