// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require stisla
//= require scripts
//= require custom

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-label/dist/leaflet.label.css';
// import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';

import L from 'leaflet';
import 'leaflet-draw';
import drawLocales from 'leaflet-draw-locales'
drawLocales('pt')

// import 'leaflet-label/dist/leaflet.label.js';
// import 'leaflet-defaulticon-compatibility';

import Swal from 'sweetalert2';
window.Swal = Swal;

import jstree from 'jstree';
window.jstree = jstree;
import "jstree/dist/themes/default/style.min";

import select2 from 'select2';
window.select2 = select2;
import "select2/dist/css/select2.min";

// import datepicker from 'bootstrap-datepicker';
// window.datepicker = datepicker;
// import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min";

import nicescroll from 'jquery.nicescroll';
window.nicescroll = nicescroll;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

const jquery = require("jquery")
const descriptor = { value: jquery, writable: false, configurable: false }
Object.defineProperties(window, { $: descriptor, jQuery: descriptor })

require("bootstrap")


// Stisla
require("vendors/stisla/mask")
require("vendors/stisla/stisla")
// require("vendors/stisla/scripts")
require("vendors/stisla/custom")

// tabela
const tabulator = require("vendors/tabulator/tabulator.min")
window.Tabulator = tabulator
// require("vendors/tabulator/xlsx.full.min.js")
// require("vendors/tabulator/jspdf.umd.min.js")
// require("vendors/tabulator/jspdf.plugin.autotable.min.js")


require("packs/jquery.multi-select")
require("packs/jquery-ui.min")
require("packs/owl.carousel.min")
require("packs/dynamic_select")
require("packs/load.tabulator")
require("packs/app")
require("packs/custom")


// import 'packs/jquery.multi-select'


require("@nathanvda/cocoon")

global.toastr = require("izitoast")

Rails.start()
Turbolinks.start()
ActiveStorage.start()
