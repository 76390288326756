$(document).on("turbolinks:load", function() {
    if($('.moeda').length){
        $('.moeda').maskMoney({
            thousands:'.', 
            decimal:',',
            precision:2
        });        
    }

    $(".file").change(function(){
        var file = $(this).get(0).files[0];
 
        if(file){
            var reader = new FileReader();
 
            reader.onload = function(){
                $(".image-preview").css('background-image', 'url(' + reader.result + ')');
            }
 
            reader.readAsDataURL(file);
        }

    })

    let sidebar_nicescroll_opts = {
        cursoropacitymin: 0,
        cursoropacitymax: .8,
        zindex: 892
      }, now_layout_class = null;
  
      var sidebar_sticky = function() {
        if($("body").hasClass('layout-2')) {
          $("body.layout-2 #sidebar-wrapper").stick_in_parent({
            parent: $('body')
          });
          $("body.layout-2 #sidebar-wrapper").stick_in_parent({recalc_every: 1});
        }
      }
      sidebar_sticky();
  
      var sidebar_nicescroll;
      var update_sidebar_nicescroll = function() {
        let a = setInterval(function() {
          if(sidebar_nicescroll != null)
            sidebar_nicescroll.resize();
        }, 10);
  
        setTimeout(function() {
          clearInterval(a);
        }, 600);
      }
  
      var sidebar_dropdown = function() {
        if($(".main-sidebar").length) {
          $(".main-sidebar").niceScroll(sidebar_nicescroll_opts);
          sidebar_nicescroll = $(".main-sidebar").getNiceScroll();
  
          $(".main-sidebar .sidebar-menu li a.has-dropdown").off('click').on('click', function() {
            var me = $(this);
  
            $('.main-sidebar .sidebar-menu li.active > .dropdown-menu').slideUp(500, function() {
              update_sidebar_nicescroll();
              return false;
            });
            $('.main-sidebar .sidebar-menu li.active').removeClass('active');
  
            if(me.parent().hasClass("active")) {
              me.parent().removeClass('active');
  
              me.parent().find('> .dropdown-menu').slideUp(500, function() {
                update_sidebar_nicescroll();
                return false;
              });
            }else{
              me.parent().addClass('active');
  
              me.parent().find('> .dropdown-menu').slideDown(500, function() {
                update_sidebar_nicescroll();
                return false;
              });
            }
  
            return false;
          });
  
          $('.main-sidebar .sidebar-menu li.active > .dropdown-menu').slideDown(500, function() {
            update_sidebar_nicescroll();
            return false;
          });
        }
      }
      sidebar_dropdown();
});