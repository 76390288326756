// app/javascript/packs/dynamic_select.js

$(document).on('turbolinks:load', function() {
    $('.select').on('change', function() {
      var id = $(this).val();
      var dynamic_selectable_url = $(this).attr('data-dynamic-selectable-url');
      var dynamic_selectable_target = $(this).attr('data-dynamic-selectable-target');

      if (dynamic_selectable_url) {
        $.ajax({
            url: dynamic_selectable_url,
            type: 'GET',
            dataType: 'json',
            data: { id: id },
            success: function(data) {
                if ( dynamic_selectable_target ) {
                  // limpar select
                  $(dynamic_selectable_target).empty().trigger('change');
                  // preencher
                  $.each(data, function(index, result) {
                      $(dynamic_selectable_target).append($('<option>', {
                          value: result.id,
                          text: result.name
                      }).trigger('change'));
                  });
                }
                
            
                
            }
        });
      } else {
        if ( dynamic_selectable_target ) {
          $(dynamic_selectable_target).empty().trigger('change');
        }
      }
    });
  });
  