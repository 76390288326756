$(document).on('turbolinks:load', function () {

 var  tabela_produtos =  document.getElementById("relatorios-table")

 
  if ( tabela_produtos !== null ) {
    var table = new Tabulator("#relatorios-table", {
      ajaxURL: "/admin/relatorio-busca-produtos", // URL para obter os dados
      layout: "fitColumns",       // Ajustar automaticamente as colunas
      columns: [
        // { title: "Status", field: "status", hozAlign:"center", width: 100, formatter:"tickCross", sorter:"boolean", editor:true },
        { title: "Nome", field: "name", headerFilter: false },
        { title: "Em estoque", field: "em_estoque", 
          formatter: function (cell) { 
            var value = parseFloat(cell.getValue());
            return value.toFixed(2); 
          } 
        }
      ],
      pagination: "local",        // Paginação local
      paginationSize: 10,          // Número de linhas por página
      movableColumns: true,       // Permite mover as colunas
      resizableRows: true,  
      locale: true,
      langs: {
        "pt-br": {
          "pagination": {
            "first": "Primeira",
            "first_title": "Primeira Página",
            "last": "Última",
            "last_title": "Última Página",
            "prev": "Anterior",
            "prev_title": "Página Anterior",
            "next": "Próxima",
            "next_title": "Próxima Página",
            "page_title": "Ir para a página",
          },
          "headerFilters": {
            "default": "Filtrar coluna...", // Placeholder padrão
            "columns": "Filtrar {col}",    // Placeholder com nome da coluna
          },
          "groups": {
            "item": "item",
            "items": "itens",
          },
          "ajax": {
            "loading": "Carregando...",    // Mensagem enquanto carrega dados
            "error": "Erro ao carregar os dados", // Mensagem de erro
          },
          "tooltips": {
            "columns": "Clique para ocultar/mostrar colunas",
          },
          "data": {
            "loading": "Carregando",        // Exibição padrão ao carregar
            "error": "Erro ao carregar dados", // Quando ocorre um erro
          },
        },
      },
      initialLocale: "pt-br", // Define o idioma inicial
    });


      //trigger download of data.xlsx file
      document.getElementById("download-xlsx").addEventListener("click", function(){
          table.download("xlsx", "data.xlsx", {sheetName:"Relatório de Produtos"});
      });

      //trigger download of data.pdf file
      document.getElementById("download-pdf").addEventListener("click", function(){
          table.download("pdf", "data.pdf", {
              orientation:"portrait", //set page orientation to portrait
              title:"Relatório de Produtos", //add title to report
          });
      });

      document.getElementById("print-table").addEventListener("click", function(){
          table.print(false, true);
      });

    //Define variables for input elements
    var fieldEl = document.getElementById("filter-field");
    var typeEl = document.getElementById("filter-type");
    var valueEl = document.getElementById("filter-value");

    //Custom filter example
    function customFilter(data){
        return data.car && data.rating < 3;
    }

    //Trigger setFilter function with correct parameters
    function updateFilter(){
    var filterVal = fieldEl.options[fieldEl.selectedIndex].value;
    var typeVal = typeEl.options[typeEl.selectedIndex].value;

    var filter = filterVal == "function" ? customFilter : filterVal;

    if(filterVal == "function" ){
        typeEl.disabled = true;
        valueEl.disabled = true;
    }else{
        typeEl.disabled = false;
        valueEl.disabled = false;
    }

    if(filterVal){
        table.setFilter(filter,typeVal, valueEl.value);
    }
    }

    //Update filters on value change
    document.getElementById("filter-field").addEventListener("change", updateFilter);
    document.getElementById("filter-type").addEventListener("change", updateFilter);
    document.getElementById("filter-value").addEventListener("keyup", updateFilter);   


    //Clear filters on "Clear Filters" button click
    document.getElementById("filter-clear").addEventListener("click", function(){
      fieldEl.value = "name";
      typeEl.value = "like";
      valueEl.value = "";

      table.clearFilter();
    });
  }
})